import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import './index.css';
import jsonData from './data.json';
import './fonts/AbhayaLibre-ExtraBold.ttf';

/*
function Square(props) {
  return (
    <button className="square" onClick={props.onClick}>
      {props.value}
    </button>
  );
}
  
class Board extends React.Component {
  renderSquare(i) {
    return <Square 
      value={this.props.squares[i]} 
      onClick={() => this.props.onClick(i)}
    />;
  }

  render() {
    return (
      <div>
        <div className="board-row">
          {this.renderSquare(0)}
          {this.renderSquare(1)}
          {this.renderSquare(2)}
        </div>
        <div className="board-row">
          {this.renderSquare(3)}
          {this.renderSquare(4)}
          {this.renderSquare(5)}
        </div>
        <div className="board-row">
          {this.renderSquare(6)}
          {this.renderSquare(7)}
          {this.renderSquare(8)}
        </div>
      </div>
    );
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [{
        squares: Array(9).fill(null),
      }],
      stepNumber: 0,
      xIsNext: true,
    }
  }
  
  handleClick(i) {
    const history = this.state.history.slice(0, this.state.stepNumber + 1);
    const current = history[history.length - 1];
    const squares = current.squares.slice();
    if (calculateWinner(squares) || squares[i]) {
      return;
    }
    squares[i] = this.state.xIsNext ? 'X' : 'O';
    this.setState({
      history: history.concat([{
        squares: squares,
      }]),
      stepNumber: history.length,
      xIsNext: !this.state.xIsNext,
    });
  }

  jumpTo(step) {
    this.setState({
      stepNumber: step,
      xIsNext: (step % 2) == 0
    });
  }

  render() {
    const history = this.state.history;
    const current = history[this.state.stepNumber];
    const winner = calculateWinner(current.squares);
    
    const moves = history.map((step, move) => {
      const desc = move ? 'Go to Move ' + move : 'Go to Game Start';
      return (
        <li key={move}>
          <button onClick={() => this.jumpTo(move)}>
            {desc}
          </button>
        </li>
      )
    });

    let status;
    if (winner) {
      status = 'Winner: ' + winner;
    } else {
      status = 'Next player: ' + (this.state.xIsNext ? 'X' : 'O');
    }

    return (
      <div className="game">
        <div className="game-board">
          <Board 
            squares={current.squares}
            onClick={(i) => this.handleClick(i)}
          />
        </div>
        <div className="game-info">
          <div>{status}</div>
          <ol>{moves}</ol>
        </div>
      </div>
    );
  }
}

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
}

*/

class Movie extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      path: './eye.png'
    }
  }
  
  watchedMovie(movieID) {
    window.localStorage.setItem("movie_" + movieID, "true");
    this.setState({
      path: './seen.png'
    })
  }

  render() {
    return (
      <div class="movie-card">
        <img src={"http://image.tmdb.org/t/p/w185" + this.props.value.poster} class="movie-image" />
        <div class="movie-details">
          <div class="movie-rank">{'#' + this.props.value.id}</div>
          <div class="movie-title">{this.props.value.name}</div>
          <div class="movie-year">{this.props.value.year}</div>
          <div class="divider"></div>
          <div class="movie-desc">{this.props.value.description}</div>
        </div>
        <div class="seen-it-link" onClick={() => this.watchedMovie(this.props.value.id)}>
            <img src={this.state.path} class="eyeball" />
            <div class="seen-it">I've Seen It!</div>
          </div>
      </div>
    )
  }
}

class Options extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      movies: [(<div>Loading Movies...</div>)]
    }
  }

  getMovies() {
    
    this.setState({
      movies: []
    })

    let movieElements = pickMovieNumbers().map((num) => {

      let movieName = jsonData[num - 1].title;

      searchMovieDb(movieName)
      .then(data => {

        let movie = {
          id: num,
          name: data.title,
          poster: data.poster_path,
          description: shortenDescription(data.overview),
          year: data.release_date.substring(0, 4)
        };
          
        const newMovie = (
          <Movie key={movie.id} value={movie} />
        );

        this.setState({
          movies: this.state.movies.concat([newMovie])
        })

      });
    });
  }

  render() {

    if (this.props.wasButtonClicked) {
      return (
        <div>
          <div class="movies-container">
            {this.state.movies}
          </div>
          <button class="generate-button" onClick={() => {this.getMovies(); this.props.onClick()}}>Generate Movies</button>
        </div>
      )
    } else {
      return (
        <button class="generate-button" onClick={() => {this.getMovies(); this.props.onClick()}}>Generate Movies</button>
      )
    }
  }
}

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      wasButtonClicked: false
    }
  }

  generateMovies() {
    this.setState({
      wasButtonClicked: true
    });
  }

  render() {
    return (
      <div>

        <img src="./title.png" class="title"/>
        <p class="description">The Internet Movie Database (ImdB) has produced their own list of the top 100 movies of all time. 
        Press the button bellow to get 3 options of movies from the list to watch, and don't worry, this 
        site uses cookies so it won't give you any repeat movies next time you visit!</p>

        <Options 
          wasButtonClicked={this.state.wasButtonClicked}
          onClick={() => this.generateMovies()}  
        />

      </div>
    );
  }
}

// ========================================

ReactDOM.render(
  <div class="background"><Main /></div>,
  document.getElementById('root')
);

function searchMovieDb(name) {
  return fetch('https://api.themoviedb.org/3/search/movie?api_key=f5a59c806e6b426988a4ca4dff51d5de&language=en-US&query=' + name.replace(' ', '%20') + '&page=1&include_adult=false')
    .then(resp => resp.json())
    .then(resp => {
      let movie = resp.results[0];
      console.log(name);

      let firstOccur = resp.results.find((elem) => {
        return elem.title.toLowerCase() == name.toLowerCase() || elem.original_title.toLowerCase() == name.toLowerCase();
      });

      console.log(firstOccur);

      return firstOccur ? firstOccur : movie;
    });
}

function pickMovieNumbers() {
  var arr = [];
  var counter = 0;
  while(arr.length < 3) {
    const r = Math.floor(Math.random() * 99) + 1;

    if (arr.indexOf(r) === -1 && !hasMovieBeenWatched(r)) {
      arr.push(r);
      counter = 0;
    }

    if(hasMovieBeenWatched(r)) counter++;
  }
  return arr;
}

function shortenDescription(desc) {
  let firstPeriod = desc.indexOf('.', 119);
  if(firstPeriod != -1) {
    return desc.substring(0, firstPeriod);
  } else {
    return desc;
  }
}

function hasMovieBeenWatched(movieID) {
  return window.localStorage.getItem('movie_' + movieID) == 'true';
}